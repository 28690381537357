@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
/* @import url("https://cdn.syncfusion.com/ej2/bootstrap5.css"); */

 
* {
     margin : 0; 
     padding: 0;
     border: 0;
     outline: 0;
     box-sizing:border-box;
     list-style: none ;
     text-decoration: none;
 }

 /* Using root variables to prevent repitition  */
 :root {
     
     --color-light : rgba(255, 255, 255, 0.6);
     --color-bg : var(--color-white);
     /* --color-bg-variant: #2c2c6c ; */
     --color-primary : #FDD947;
     /* --color-primary-variant: rgba(77, 181, 255 ,0.4); */
     

     --transition : all 400ms ease; 

     --container-width-lg : 75% ;
     --container-width-md : 86% ;
     --container-width-sm : 92% ;
 }

 html{
     scroll-behavior:  smooth;
 }

 /* No scroll controller */
 ::-webkit-scrollbar{
     display:  none;
 }

 body {
     font-family: 'Montserrat', sans-serif;
     background: var(--color-white);
     background: var(--color-bg );
     color: var(--color-white);
     line-height: 1.7; 
     /* background-image: url(../src/assets/bg2_texture.png); */
 }

 /* GENERAL STYLES */

 .container{
     width : 75%;
     width : var(--container-width-lg);
     margin: 0 auto;
 }

 h1,h2,h3,h4,h5{
    font-weight:  500;
 }

 h1 {
     font-size: 2.2rem;
 }


 section{
     margin-top: 8rem;
 }

 /* section > h2, section> h5 {
     text-align: center;
     color: var(--color-light);
 }

 section> h2 {
     color:  var(--color-primary);
     margin-bottom: 3rem;
 } */

 /* .text-light {
     color: var(--color-light);
 } */

 /* a{
     color: var(--color-primary);
     transition:  var(--transition);
 } */

 /* a:hover {
     color: var(--color-white);
 } */

 .btn {
     width:  -webkit-max-content ;
     width:  max-content ;
     display: inline-block; 
     color: #FDD947; 
     color: var(--color-primary);
     padding: 0.75rem 1.2rem;
     /* border-radius: 0.4rem; */
     cursor:pointer ;
     border:  1px solid #FDD947;
     border:  1px solid var(--color-primary);
     /* transition: var(--transition); */
 } 

 /* .btn:hover {
     background: var(--color-white);
     color: var(--color-bg);
     border-color: transparent;

 } */

 /* .btn-primary {
     background: var(--color-primary);
     color: var(--color-bg);
 } */

 img {
     display: block;
     width: 100%;
     object-fit: cover;
 }

 /* MEDIA QUERIES (MEDIUM DEVICES e.g Tablets) */

 @media screen and (max-width : 1024px) {
     .container{
         width: 86%;
         width: var(--container-width-md);
        }  
        
        section {
            margin-top: 6rem;
        }
 }


  /* MEDIA QUERIES (SMALLL DEVICES e.g Mobile Phones) */

  @media screen and (max-width : 600px) {
    .container{
        width: 92%;
        width: var(--container-width-sm);
       }  
       
       section > h2 {
           margin-bottom: 2rem;
       }
}
.header {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 45px;
    border-bottom: 1px solid rgb(233, 224, 224);
    position: fixed;
    z-index: 10;
    cursor: pointer;
}

/* .logo-menu {
    display: flex;
    align-items: left;   
} */


#lgmen {
    align-items: center;
    display: flex;
}

.header-bg {
    background: rgba(0, 0, 0, 0.9);
}

.header img {
    width: 160px;
    height: 65px;
    margin-top: 15px;
    margin-bottom: 10px;
    object-fit: fill;
    /* border-right: 2px solid rgb(233, 224, 224); */
    padding-right: 5%;
    
}

/* .logo{
    height: 100px;
    width: 120px;
} */

.logo:after {
    content: '';
    position: absolute;
    width: 196px;
    /* padding-top:30px ; */
    margin-top: 25px;
    margin-bottom: 20px;
    height: 30px;
    padding-left: 10px;
    padding-right: 0px;
    margin-right: 0;
    border-right: 2px solid rgb(233, 224, 224);
    left: 1px;
    top: 1px;
}



.header-menu {
    color: #fff;
    display: flex;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 600;
    font-size: 12px;
    /* padding: 0px; */
    margin-left: 5px;
    top: 50%;
}

/* .header ul{
    border-left: 1px solid rgb(233, 224, 224);
    padding: 0 40px;
} */
.header-item {
    padding: 20px;

}
.header-item .active{
    border-bottom: 1px solid white;
}

.header-item:hover {
    color: rgb(214, 210, 210);
    transition: all 0.4s ease;
    
}

.header-menu p {
    display: none;
    cursor: default;
}



.header-menu button {
    border: 2px solid #fff;
    padding: 4px 12px 3px;
    letter-spacing: 1px;
    font-size: 9px;
    color: #fff;
    background: transparent;
    font-weight: 600;
    transition: ease-out 0.3s;
    margin-left: 10px;
    cursor: pointer;

}

.header-menu button::before {
    flex-grow: 1;
}

.header-menu p {
    display: none;
    /* font-weight: 600; */
}

/* .header-menu button {
    border: 2px solid black;
    padding: 3px 9px 3px 9px;
    font-size: xx-small;
    margin-left: 5px;
    background-color: transparent;
    box-shadow: 0 0 0 0 white;
    transition: ease-out 0.3s;

} */

.header-menu button:hover {
    box-shadow: inset 300px 0 0 0 white;
    color: black;
}

.hamburger {
    color: black;
    display: none;

}

/* MEDIA QUERIES (SMALL AND MEDIUM DEVICES e.g Phones, Tablets) */

@media screen and (max-width: 992px) {
    .header {
        padding: 0 10px;
    }

    .logo:after {
        border: none;
    }

    .header-menu {
        position: fixed;
        top: 80px;
        left: -100%;
        flex-direction: column;
        background-color: black;
        color: #fff;
        width: 100%;
        height: 45%;
        text-align: left;
        transition: 0.4s;
    }


    .header ul {
        padding: 0px;
    }

    .header img {
        border-right: none;
        width: 150px;
    }

    .header-menu.active {
        left: 0;

    }

    .header-item {
        justify-content: center;
        z-index: 10000;
    }

    .menu {
        display: none;
    }

    .hamburger {
        display: flex;
        color: #fff;
        z-index: 10000;
    }
}


/* MEDIA QUERIES (LARGE DEVICES e.g PCs) */
@media screen and (min-width : 1200px) {
    .header-menu p {
        display: flex;
        padding: 0 4px;
    }
}
.hero {
    position: relative;
    -webkit-user-select: none;
            user-select: none;

}

.hero::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: black;
    opacity: 0.65;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
    background: grey;
    width: 40px;
    padding-left: 10px;

    height: 3px;
    margin-bottom: 20px;
    border-radius: 0;
}

.swiper-pagination-clickable .swiper-pagination-bullet-active {
    background: white;
    width: 40px;
    padding-left: 10px;

    height: 3px;
    margin-bottom: 20px;

}

/* target the active bullet */
/* span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: blue;
    opacity: 1;
  }
  
  /* target all bullets */
/* .swiper-pagination-bullet {
    background-color: red;
    opacity: 1;
  } */

.hero-swiper {
    width: 100vw;
    height: 100%;
    display: flex;
    /* flex-direction: row;
    justify-content: start; */

}

.hero img {
    width: 100vw;
    height: 100vh;
    background-size: cover;
    display: flex;
    background-position: center;
}

.hero-text {
    color: #fff;
    position: absolute;
    top: 20%;
    z-index: 1;
    padding-left: 80px;
    width: 100%;
}

.hero-text h1 {
    font-weight: 700;
    font-size: 100px;
    line-height: 100px;
    text-transform: uppercase;
    letter-spacing: 3px;
    width: 80%;

}

.separator {
    width: 50%;
    margin-top: 5%;
    background-color: #FDD947;
    height: 8px;
}

.hero-text p {
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-top: 5%;
    width: 80%;
}

.hero-btn {
    position: absolute;
    bottom: 5%;
    left: 45%;
    z-index: 100;
    color: #fff;
}



@media screen and (max-width: 480px) {

    .hero-text {
        top: 40%;
        padding-left: 30px;

    }

    .hero-text h1 {
        font-size: 34px;
        line-height: 38px;
        width: 80%;
        letter-spacing: 0px;
    }

    .separator {
        width: 60%;
    }

    .hero-text p {
        width: 80%;
    }

    .hero-btn {
        left: 40%;
        bottom: 10%;
    }
}


@media screen and (min-width : 500px) {
    .hero-text h1 {
        font-size: 56px;
        line-height: 60px;
    }

}


@media screen and (min-width : 900px) {

    .hero-text h1 {
        font-size: 100px;
        line-height: 100px;
    }


}
.container {
    border-bottom: 1px solid rgb(233, 224, 224);
}

.about {
    margin: 5%;
    padding: 50px;
}

.about-title {
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 2px;
    color: #000;
    padding-bottom: 10px;
    text-decoration: underline;
    text-decoration-thickness: 5px;
    padding-top: 15px;
    text-underline-offset: 6px;
    padding-bottom: 25px;
    margin-bottom: 15px;


}

.about-section {
    color: #000;
    display: block;
}

.intro-text1 {
    margin: 0 0 43px;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
}

.intro-text2 {
    color: #626262;
    font-size: 14px;
    line-height: 24px;
    margin: 0 0 10px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;

}

.intro-btn {
    margin-top: 20px;
    font-size: 14px;
    padding: 10px 22px;
    border: 4px solid #000;
    background-color: transparent;
    width: auto;
    font-weight: 500;
    letter-spacing: 1px;
    color: black;
    transition: ease-out 0.3s;
    margin: 40px 0;
}

.intro-btn:hover {
    background-color: #000;
    color: #fff;
}

.about-section2 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 8;
    gap: 8;
}

.about-section2 div {
    padding: 0 15px;
}


.about-section2 span {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin: 0 0 10px;
    color: #000000;

}

hr {
    border: 0;
    clear: both;
    display: block;
    width: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #FDD947;
    height: 1px;
}

.about-text {
    width: 90%;
    font-size: 14px;
    line-height: 24px;
    margin: 0 0 20px;
    color: #626262;
    font-family: 'Roboto', sans-serif;
}

.about-text::before {
    height: 3px;
    width: 10px;
    color: #FDD947;

}

.counter {
    border-top: 1px solid rgba(0, 0, 0, .1);
    padding: 80px 0;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin-bottom: 5%;
}

.counter div {
    padding: 0 60px;
    margin: 0 30px;
    border-right: 1px solid rgba(0, 0, 0, .1);
}

.counter div:nth-last-child(1) {
    border: none;
}

.counter span {
    font-size: 35px;
    font-weight: 700;
    color: #000;
}

.counter p {
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 2px;
    line-height: 20px;
    text-transform: uppercase;
    color: #626262;
}


@media screen and (max-width : 767px) {
    .about {
        margin: 2%;
        padding: 15px;
        text-align: center;
    }

    .about-title {
        letter-spacing: 0;
    }

    .intro-text1 {
        margin: 0 0 20px;
    }

    .about-section2 {
        display: block;
    }

    .about-text {
        width: 100%;
    }

    .counter {
        padding: 40px 0;
        display: block;
    }

    .counter div {
        text-align: center;
        padding: 20px 0px;
        margin: 30px 0px;
        border-right: 0px;
    }
}

@media screen and (min-width : 990px) {
    .about {
        margin: 6%;
        padding: 15px;
    }

    .about-section {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .intro-text1 {
        margin: 0 0 22px;
        width: 70%;
    }

    .intro-text2 {
        width: 80%;
    }

    .about-section2 {
        margin-left: 5%;
    }

    .about-section2 div {
        padding: 0;
    }

    .about-section2 span {
        margin: 0 0 6px;
    }

    .about-text {
        margin: 0 0 10px;
    }
}

.service-img {
  display: none;
}
.service-section2 {
  background-color: #f6f6f6;
  padding: 10px 0;
}

.service-section-div div{
 margin: 15px 15px;
 vertical-align: middle;
 justify-content: center;
 padding: 15px 15px;
  border: 6px solid transparent;
}


.service-section-div div:hover {
  background-color: white;
  border: 6px solid #FDD947;
  
}

.service-section2 span {
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  
  margin: 0 0 10px;
  color: #000000;
}
.service-text {
  width: 90%;
  font-size: 13px;
  line-height: 24px;
  margin: 0 0 20px;
  color: #626262;
  justify-self: middle; 
  align-self: middle ;
  font-family: "Roboto", sans-serif;
}

@media screen and (min-width: 1200px) {
  .service {
    display: flex;
  }
  .service-img {
    width: 50%;
    height: 100vh;
  }
  .service-section2 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding: 10px;
  }
  .service-section2 div {
    margin: 5px 25px;
  }
}
@media screen and (min-width: 768px) {
  .parallax-fix {
    position: relative;
    background-size: cover !important;
    overflow: hidden;
    background-position: center center;
    background-repeat: no-repeat !important;
    transition-duration: .2s;
    -moz-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    -o-transition-duration: .2s;
    transition-timing-function: ease-in;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    background-attachment: fixed !important;
}
  .service {
    width: full;
  }
  .service-img {
    width: 100%;
    max-width: 600px;
    display: block;
    height: 600px;
    background: url(/static/media/laptop.bf90d46a.jpg) 50%;
  }
  .service-section2 {
    background-color: #f6f6f6;
    padding: 50px;
  }
  .service-section2 div {
    /*padding: 5px 50px;*/
    text-align: center;
    /*margin: 15px 25px;*/
    border: 6px solid transparent;
  }
}

@media screen and (min-width: 1024px) {
  .service-img{
    max-width: 100%;
  }
  .service-section2 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding: 10px;
  }
}

.section {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 50px;
}
.team-header {
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 2px;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 20px;
  margin-bottom: 100px;
}

.team-text {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}

.team-btn {
  text-align: center;
  background-color: transparent;
  border: 4px solid black;
  font-size: 14px;
  text-transform: uppercase;
  padding: 10px 5px;
  font-weight: 600;
  cursor: pointer;
  margin: 15px 65px;
  color: black;
}
.team-btn:hover {
  background-color: black;
  color: white;
}
.team-counter {
  text-align: center;
}
.team-counter span {
  font-size: 35px;
  font-weight: 700;
}
.team-counter p {
  font-size: 11px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #626262;
}
.team-galleries {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.team-gallery {
  position: relative;
}
.team-img {
  height: 100%;
  width: 100%;
}
.overlay-text-wrapper {
  position: absolute;

  top: 35%;
  right: 28%;
}
.overlay-text-wrapper p {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 3px;
  text-transform: uppercase;
  z-index: 10;
  padding: 8px 14px;
  background-color: #fff;
  width: 100%;
}
.team-gallery:hover .overlay-text-wrapper p {
  display: none;
}
.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fdd947;
  opacity: 0.9;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.5s ease;
}
.overlay h2 {
  font-weight: 600;
  letter-spacing: 2px;
  font-size: 15px;
  text-transform: uppercase;
  text-align: center;
}
.overlay p {
  font-size: 11px;
  text-transform: uppercase;
  text-align: center;
}
.overlay-line {
  height: 2px;
  width: 100%;
  background-color: white;
  margin: 10px 0px;
}
.team-socials {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.socials {
  color: black;
}
.team-gallery:hover .overlay {
  height: 65%;
  padding: 50px;
}

@media (max-width: 320px) {
  .team-btn {
    padding: 10px 5px;
    margin: 15px 8%;
  }
  .overlay-text-wrapper {
    position: absolute;
    top: 35%;
    right: 13%;
  }
}

@media screen and (min-width: 768px) {
  .section {
    padding: 5%;
  }
  .team-header {
    text-align: left;
    text-decoration: underline;
    -webkit-text-decoration-color: #fdd947;
            text-decoration-color: #fdd947;
    text-underline-offset: 6px;
    text-decoration-thickness: 5px;
    padding-bottom: 25px;
    padding-top: 15px;
  }

  .team-text {
    text-align: left;
  }
  .team-btn {
    padding: 10px 5px;

    margin: 15px 30%;
  }
  .team-counter {
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
  }
  .team-galleries {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media screen and (min-width: 1000px) {
  .team-section {
    display: flex;
  }
  .team-about {
    width: 40%;
  }
  .team-btn {
    margin-top: 30px;
    margin-left: 0;
  }
  .team-counter {
    padding-top: 0;
    width: 60%;
  }
  .team-counter p {
    letter-spacing: 0px;
  }
  .overlay-text-wrapper {
    position: absolute;
    top: 35%;
    right: 26%;
  }
  .team-galleries {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.contact {
  margin: 2% 2%;
  padding: 2%;
}

.contact-header {
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 2px;
  text-align: center;
}



.contact-section1 {
  display: block;
}

.contact-text {
  margin: 0 0 40px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  width: 100%;
}

.contact-info {
  font-size: 20px;
  display: block;
}

.contact-info p {
  display: flex;
  justify-content: center;
  margin: 0 10px;
}

.contact-section2 {
  display: block;
}

.contact-container {
  padding-top: 30px;
  width: 100%;
  text-align: center;
}

.contact-details {
  padding-bottom: 10px;
}

.contact-details span {
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
}

.contact-details p {
  font-size: 14px;
  color: #626262;
  width: 100%;
  padding: 0px 70px;
}
.contact-details a{
  font-size: 14px;
  color: #626262;
}

form input {
  margin: 0 0px;
  border: 2px solid #dfdfdf;
  padding: 18px 25px;
  margin: 0 0 20px;
  width: 95vw;
}

::-webkit-input-placeholder {
  color: #c0bdbd;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

::placeholder {
  color: #c0bdbd;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.custom-dropdown {
  position: relative;
}

.dropdown-selection {
  /* margin: 0 50px; */
 /* border: 2px solid #dfdfdf;  */
  padding: 18px 0px;
  margin: 0 0 20px;
  color: #c0bdbd;
  cursor: pointer;
  position: relative;
  width: 95vw;
}

.dropdown-selection::after {
  display: block;
  content: "";
  width: 10px;
  height: 10px;
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  position: absolute;
  left: 90%;
  top: 35%;
}

.items-holder {
  border: 1px solid #dfdfdf;
  background-color: white;
  position: absolute;
  top: 100%;
  color: #c0bdbd;
  width: 95vw;
}

.items-holder:hover {
  border: 1px solid black;
}

.items-holder .dropdown-item {
  padding-left: 30px;
  cursor: pointer;
}

.items-holder .dropdown-item:hover {
  background-color: rgb(113, 113, 165);
}

.contact button {
  font-size: 14px;
  margin-left: 31%;
  color: white;
  border: 2px solid black;
  background-color: black;
  text-transform: uppercase;
  padding: 10px 22px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 80px;
}

.contact button:hover {
  background-color: white;
  color: #000;
}

@media screen and (min-width: 768px) {
  .contact-header {
    text-align: left;
    text-decoration: underline;
    -webkit-text-decoration-color: yellow;
            text-decoration-color: yellow;
    -webkit-text-decoration-color: #FDD947;
            text-decoration-color: #FDD947;
    text-decoration-thickness: 5px;
    text-underline-offset: 6px;
    padding-bottom: 25px;
    margin-bottom: 15px;
  }


  .contact-text {
    text-align: left;
  }

  .contact-info {
    display: flex;
  }

  .contact-container {
    padding-top: 30px;
    width: 100%;
    display: flex;
  }

  .contact-details p {
    padding: 0px 10px;
  }

  form input {
    width: 90vw;
  }

  form {
    margin-top: 20px;
  }

  .dropdown-selection {
    width: 90vw;
  }

  .items-holder {
    width: 90vw;
  }

  .contact button {
    margin-left: 0%;
  }
}

@media screen and (min-width: 1000px) {
  .contact {
    margin: 4% 4%;
    padding: 4%;
  }

  .contact-section1 {
    display: flex;
    justify-content: space-between;
  }

  .contact-text {
    width: 35%;
  }

  .contact-info {
    display: flex;
    margin-top: 10px;
  }

  .contact-section2 {
    display: flex;
  }

  .contact-container {
    display: block;
    text-align: left;
  }

  .contact-details p {
    width: 100%;
    padding: 0px;
  }

  form input {
    width: 50vw;
  }

  .dropdown-selection {
    width: 50vw;
  }

  .items-holder {
    width: 50vw;
  }

  .contact button {
    margin-left: 40%;

  }
}
.heading {
  text-decoration: underline;
  -webkit-text-decoration-color: #fdd947;
          text-decoration-color: #fdd947;
  text-align: center;
  text-decoration-thickness: 5px;
  font-weight: 600;
  letter-spacing: 2px;
  text-underline-offset: 6px;
  font-size: 40px;
  padding: 0 0 15px 0;
  margin: 60px 0;
}

.portfolio-text {
  text-align: center;
  font-size: 18px;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-top: 8%;
}
.portfolio-apps {
  justify-content: center;
  align-items: center;
}
.portfolio-app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 0px;
  padding: 10px 15px;
}
span a {
  font-size: 11px;
  font-family: "Roboto", sans-serif;
  margin: 9px 9px 0;
  letter-spacing: 2px;
  color: #626262;
  text-transform: uppercase;
}
.skills-header {
  margin-top: 10px;
}
.skillheader-text {
  background-color: #000;
  color: white;
  font-size: 28px;
  letter-spacing: 2px;
  padding: 10%;
  font-weight: 500;
}
.skill-img {
  display: none;
}
.skill-bar {
  background-color: #f6f6f6;
  padding: 40px;
}
.progress-bar {
  background-color: #9b9191;
  width: 100%;
  height: 5px;
  margin-bottom: 50px;
}

.video {
  height: 5px;
  width: 90%;
  background-color: #000;
}
.progressbar-text {
  padding-top: 10px;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 2px;
  color: #000;
  font-family: "Roboto", sans-serif;
}
.progressbar-text span {
  color: #626262;
}
.audio {
  height: 5px;
  width: 85%;
  background-color: #000;
}
.marketing {
  height: 5px;
  width: 80%;
  background-color: #000;
}
.curation {
  height: 5px;
  width: 75%;
  background-color: #000;
}

@media screen and (min-width: 768px) {
  .gallerysection {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .portfolio-apps {
    display: flex;
    justify-content: space-evenly;
    padding-top: 20px;
    padding-bottom: 50px;
  }
  .skill-bar {
    padding: 80px 70px;
  }
  .curation {
    width: 60%;
  }
}
@media screen and (min-width: 1200px) {
  .heading {
    text-align: left;
    margin: 60px 40px;
  }
  .gallerysection {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .gallery {
    position: relative;
    cursor: crosshair;
  }
  .gallery:hover .gallery_img {
    opacity: 0.5;
  }
  .gallery_img {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: 0.5s ease;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
  }
  .gallery-frame {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    transition: 0.5s ease;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .gallery:hover .gallery-frame {
    opacity: 0.8;
  }
  .gallery-frame-child {
    height: 150px;
    width: 150px;
    border: 5px solid yellow;
  }
  .gallery-frame:hover {
    background-color: white;
    opacity: 0.1;
  }
  .skills-header {
    display: flex;
    height: 100vh;
  }
  .skillheader-text {
    width: 30%;
  }
  .skill-img {
    display: block;
    height: 100vh;
    width: 30%;
  }
  .skill-bar {
    width: 40%;
  }

  .progress-bar {
    margin-bottom: 80px;
  }
  .progressbar-text {
    padding-top: 30px;
  }
  .marketing {
    width: 85%;
  }
  .curation {
    width: 75%;
  }
}

.footer {
  background-color: #c9c7c7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-logo {
  max-width: 110px;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
}
.footer-text{
  font-weight: 500;
  letter-spacing: 1px;
  color: white;
  font-size: 11px;
  text-transform: uppercase;
}
.footer-socials {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.footer-app {
  
  color: white;
  margin: 0 10px;
}

