.heading {
  text-decoration: underline;
  text-decoration-color: #fdd947;
  text-align: center;
  text-decoration-thickness: 5px;
  font-weight: 600;
  letter-spacing: 2px;
  text-underline-offset: 6px;
  font-size: 40px;
  padding: 0 0 15px 0;
  margin: 60px 0;
}

.portfolio-text {
  text-align: center;
  font-size: 18px;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-top: 8%;
}
.portfolio-apps {
  justify-content: center;
  align-items: center;
}
.portfolio-app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 0px;
  padding: 10px 15px;
}
span a {
  font-size: 11px;
  font-family: "Roboto", sans-serif;
  margin: 9px 9px 0;
  letter-spacing: 2px;
  color: #626262;
  text-transform: uppercase;
}
.skills-header {
  margin-top: 10px;
}
.skillheader-text {
  background-color: #000;
  color: white;
  font-size: 28px;
  letter-spacing: 2px;
  padding: 10%;
  font-weight: 500;
}
.skill-img {
  display: none;
}
.skill-bar {
  background-color: #f6f6f6;
  padding: 40px;
}
.progress-bar {
  background-color: #9b9191;
  width: 100%;
  height: 5px;
  margin-bottom: 50px;
}

.video {
  height: 5px;
  width: 90%;
  background-color: #000;
}
.progressbar-text {
  padding-top: 10px;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 2px;
  color: #000;
  font-family: "Roboto", sans-serif;
}
.progressbar-text span {
  color: #626262;
}
.audio {
  height: 5px;
  width: 85%;
  background-color: #000;
}
.marketing {
  height: 5px;
  width: 80%;
  background-color: #000;
}
.curation {
  height: 5px;
  width: 75%;
  background-color: #000;
}

@media screen and (min-width: 768px) {
  .gallerysection {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .portfolio-apps {
    display: flex;
    justify-content: space-evenly;
    padding-top: 20px;
    padding-bottom: 50px;
  }
  .skill-bar {
    padding: 80px 70px;
  }
  .curation {
    width: 60%;
  }
}
@media screen and (min-width: 1200px) {
  .heading {
    text-align: left;
    margin: 60px 40px;
  }
  .gallerysection {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .gallery {
    position: relative;
    cursor: crosshair;
  }
  .gallery:hover .gallery_img {
    opacity: 0.5;
  }
  .gallery_img {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: 0.5s ease;
    backface-visibility: hidden;
  }
  .gallery-frame {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    transition: 0.5s ease;
    opacity: 0;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .gallery:hover .gallery-frame {
    opacity: 0.8;
  }
  .gallery-frame-child {
    height: 150px;
    width: 150px;
    border: 5px solid yellow;
  }
  .gallery-frame:hover {
    background-color: white;
    opacity: 0.1;
  }
  .skills-header {
    display: flex;
    height: 100vh;
  }
  .skillheader-text {
    width: 30%;
  }
  .skill-img {
    display: block;
    height: 100vh;
    width: 30%;
  }
  .skill-bar {
    width: 40%;
  }

  .progress-bar {
    margin-bottom: 80px;
  }
  .progressbar-text {
    padding-top: 30px;
  }
  .marketing {
    width: 85%;
  }
  .curation {
    width: 75%;
  }
}
