.container {
    border-bottom: 1px solid rgb(233, 224, 224);
}

.about {
    margin: 5%;
    padding: 50px;
}

.about-title {
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 2px;
    color: #000;
    padding-bottom: 10px;
    text-decoration: underline;
    text-decoration-thickness: 5px;
    padding-top: 15px;
    text-underline-offset: 6px;
    padding-bottom: 25px;
    margin-bottom: 15px;


}

.about-section {
    color: #000;
    display: block;
}

.intro-text1 {
    margin: 0 0 43px;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
}

.intro-text2 {
    color: #626262;
    font-size: 14px;
    line-height: 24px;
    margin: 0 0 10px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;

}

.intro-btn {
    margin-top: 20px;
    font-size: 14px;
    padding: 10px 22px;
    border: 4px solid #000;
    background-color: transparent;
    width: auto;
    font-weight: 500;
    letter-spacing: 1px;
    color: black;
    transition: ease-out 0.3s;
    margin: 40px 0;
}

.intro-btn:hover {
    background-color: #000;
    color: #fff;
}

.about-section2 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 8;
}

.about-section2 div {
    padding: 0 15px;
}


.about-section2 span {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin: 0 0 10px;
    color: #000000;

}

hr {
    border: 0;
    clear: both;
    display: block;
    width: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #FDD947;
    height: 1px;
}

.about-text {
    width: 90%;
    font-size: 14px;
    line-height: 24px;
    margin: 0 0 20px;
    color: #626262;
    font-family: 'Roboto', sans-serif;
}

.about-text::before {
    height: 3px;
    width: 10px;
    color: #FDD947;

}

.counter {
    border-top: 1px solid rgba(0, 0, 0, .1);
    padding: 80px 0;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin-bottom: 5%;
}

.counter div {
    padding: 0 60px;
    margin: 0 30px;
    border-right: 1px solid rgba(0, 0, 0, .1);
}

.counter div:nth-last-child(1) {
    border: none;
}

.counter span {
    font-size: 35px;
    font-weight: 700;
    color: #000;
}

.counter p {
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 2px;
    line-height: 20px;
    text-transform: uppercase;
    color: #626262;
}


@media screen and (max-width : 767px) {
    .about {
        margin: 2%;
        padding: 15px;
        text-align: center;
    }

    .about-title {
        letter-spacing: 0;
    }

    .intro-text1 {
        margin: 0 0 20px;
    }

    .about-section2 {
        display: block;
    }

    .about-text {
        width: 100%;
    }

    .counter {
        padding: 40px 0;
        display: block;
    }

    .counter div {
        text-align: center;
        padding: 20px 0px;
        margin: 30px 0px;
        border-right: 0px;
    }
}

@media screen and (min-width : 990px) {
    .about {
        margin: 6%;
        padding: 15px;
    }

    .about-section {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .intro-text1 {
        margin: 0 0 22px;
        width: 70%;
    }

    .intro-text2 {
        width: 80%;
    }

    .about-section2 {
        margin-left: 5%;
    }

    .about-section2 div {
        padding: 0;
    }

    .about-section2 span {
        margin: 0 0 6px;
    }

    .about-text {
        margin: 0 0 10px;
    }
}