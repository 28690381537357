.contact {
  margin: 2% 2%;
  padding: 2%;
}

.contact-header {
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 2px;
  text-align: center;
}



.contact-section1 {
  display: block;
}

.contact-text {
  margin: 0 0 40px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  width: 100%;
}

.contact-info {
  font-size: 20px;
  display: block;
}

.contact-info p {
  display: flex;
  justify-content: center;
  margin: 0 10px;
}

.contact-section2 {
  display: block;
}

.contact-container {
  padding-top: 30px;
  width: 100%;
  text-align: center;
}

.contact-details {
  padding-bottom: 10px;
}

.contact-details span {
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
}

.contact-details p {
  font-size: 14px;
  color: #626262;
  width: 100%;
  padding: 0px 70px;
}
.contact-details a{
  font-size: 14px;
  color: #626262;
}

form input {
  margin: 0 0px;
  border: 2px solid #dfdfdf;
  padding: 18px 25px;
  margin: 0 0 20px;
  width: 95vw;
}

::placeholder {
  color: #c0bdbd;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.custom-dropdown {
  position: relative;
}

.dropdown-selection {
  /* margin: 0 50px; */
 /* border: 2px solid #dfdfdf;  */
  padding: 18px 0px;
  margin: 0 0 20px;
  color: #c0bdbd;
  cursor: pointer;
  position: relative;
  width: 95vw;
}

.dropdown-selection::after {
  display: block;
  content: "";
  width: 10px;
  height: 10px;
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
  transform: rotate(45deg);
  position: absolute;
  left: 90%;
  top: 35%;
}

.items-holder {
  border: 1px solid #dfdfdf;
  background-color: white;
  position: absolute;
  top: 100%;
  color: #c0bdbd;
  width: 95vw;
}

.items-holder:hover {
  border: 1px solid black;
}

.items-holder .dropdown-item {
  padding-left: 30px;
  cursor: pointer;
}

.items-holder .dropdown-item:hover {
  background-color: rgb(113, 113, 165);
}

.contact button {
  font-size: 14px;
  margin-left: 31%;
  color: white;
  border: 2px solid black;
  background-color: black;
  text-transform: uppercase;
  padding: 10px 22px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 80px;
}

.contact button:hover {
  background-color: white;
  color: #000;
}

@media screen and (min-width: 768px) {
  .contact-header {
    text-align: left;
    text-decoration: underline;
    text-decoration-color: yellow;
    text-decoration-color: #FDD947;
    text-decoration-thickness: 5px;
    text-underline-offset: 6px;
    padding-bottom: 25px;
    margin-bottom: 15px;
  }


  .contact-text {
    text-align: left;
  }

  .contact-info {
    display: flex;
  }

  .contact-container {
    padding-top: 30px;
    width: 100%;
    display: flex;
  }

  .contact-details p {
    padding: 0px 10px;
  }

  form input {
    width: 90vw;
  }

  form {
    margin-top: 20px;
  }

  .dropdown-selection {
    width: 90vw;
  }

  .items-holder {
    width: 90vw;
  }

  .contact button {
    margin-left: 0%;
  }
}

@media screen and (min-width: 1000px) {
  .contact {
    margin: 4% 4%;
    padding: 4%;
  }

  .contact-section1 {
    display: flex;
    justify-content: space-between;
  }

  .contact-text {
    width: 35%;
  }

  .contact-info {
    display: flex;
    margin-top: 10px;
  }

  .contact-section2 {
    display: flex;
  }

  .contact-container {
    display: block;
    text-align: left;
  }

  .contact-details p {
    width: 100%;
    padding: 0px;
  }

  form input {
    width: 50vw;
  }

  .dropdown-selection {
    width: 50vw;
  }

  .items-holder {
    width: 50vw;
  }

  .contact button {
    margin-left: 40%;

  }
}