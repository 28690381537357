.section {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 50px;
}
.team-header {
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 2px;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 20px;
  margin-bottom: 100px;
}

.team-text {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}

.team-btn {
  text-align: center;
  background-color: transparent;
  border: 4px solid black;
  font-size: 14px;
  text-transform: uppercase;
  padding: 10px 5px;
  font-weight: 600;
  cursor: pointer;
  margin: 15px 65px;
  color: black;
}
.team-btn:hover {
  background-color: black;
  color: white;
}
.team-counter {
  text-align: center;
}
.team-counter span {
  font-size: 35px;
  font-weight: 700;
}
.team-counter p {
  font-size: 11px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #626262;
}
.team-galleries {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.team-gallery {
  position: relative;
}
.team-img {
  height: 100%;
  width: 100%;
}
.overlay-text-wrapper {
  position: absolute;

  top: 35%;
  right: 28%;
}
.overlay-text-wrapper p {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 3px;
  text-transform: uppercase;
  z-index: 10;
  padding: 8px 14px;
  background-color: #fff;
  width: 100%;
}
.team-gallery:hover .overlay-text-wrapper p {
  display: none;
}
.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fdd947;
  opacity: 0.9;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.5s ease;
}
.overlay h2 {
  font-weight: 600;
  letter-spacing: 2px;
  font-size: 15px;
  text-transform: uppercase;
  text-align: center;
}
.overlay p {
  font-size: 11px;
  text-transform: uppercase;
  text-align: center;
}
.overlay-line {
  height: 2px;
  width: 100%;
  background-color: white;
  margin: 10px 0px;
}
.team-socials {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.socials {
  color: black;
}
.team-gallery:hover .overlay {
  height: 65%;
  padding: 50px;
}

@media (max-width: 320px) {
  .team-btn {
    padding: 10px 5px;
    margin: 15px 8%;
  }
  .overlay-text-wrapper {
    position: absolute;
    top: 35%;
    right: 13%;
  }
}

@media screen and (min-width: 768px) {
  .section {
    padding: 5%;
  }
  .team-header {
    text-align: left;
    text-decoration: underline;
    text-decoration-color: #fdd947;
    text-underline-offset: 6px;
    text-decoration-thickness: 5px;
    padding-bottom: 25px;
    padding-top: 15px;
  }

  .team-text {
    text-align: left;
  }
  .team-btn {
    padding: 10px 5px;

    margin: 15px 30%;
  }
  .team-counter {
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
  }
  .team-galleries {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media screen and (min-width: 1000px) {
  .team-section {
    display: flex;
  }
  .team-about {
    width: 40%;
  }
  .team-btn {
    margin-top: 30px;
    margin-left: 0;
  }
  .team-counter {
    padding-top: 0;
    width: 60%;
  }
  .team-counter p {
    letter-spacing: 0px;
  }
  .overlay-text-wrapper {
    position: absolute;
    top: 35%;
    right: 26%;
  }
  .team-galleries {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
