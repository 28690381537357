
.service-img {
  display: none;
}
.service-section2 {
  background-color: #f6f6f6;
  padding: 10px 0;
}

.service-section-div div{
 margin: 15px 15px;
 vertical-align: middle;
 justify-content: center;
 padding: 15px 15px;
  border: 6px solid transparent;
}


.service-section-div div:hover {
  background-color: white;
  border: 6px solid #FDD947;
  
}

.service-section2 span {
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  
  margin: 0 0 10px;
  color: #000000;
}
.service-text {
  width: 90%;
  font-size: 13px;
  line-height: 24px;
  margin: 0 0 20px;
  color: #626262;
  justify-self: middle; 
  align-self: middle ;
  font-family: "Roboto", sans-serif;
}

@media screen and (min-width: 1200px) {
  .service {
    display: flex;
  }
  .service-img {
    width: 50%;
    height: 100vh;
  }
  .service-section2 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding: 10px;
  }
  .service-section2 div {
    margin: 5px 25px;
  }
}
@media screen and (min-width: 768px) {
  .parallax-fix {
    position: relative;
    background-size: cover !important;
    overflow: hidden;
    background-position: center center;
    background-repeat: no-repeat !important;
    transition-duration: .2s;
    -moz-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    -o-transition-duration: .2s;
    transition-timing-function: ease-in;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    background-attachment: fixed !important;
}
  .service {
    width: full;
  }
  .service-img {
    width: 100%;
    max-width: 600px;
    display: block;
    height: 600px;
    background: url(../img/laptop.jpg) 50%;
  }
  .service-section2 {
    background-color: #f6f6f6;
    padding: 50px;
  }
  .service-section2 div {
    /*padding: 5px 50px;*/
    text-align: center;
    /*margin: 15px 25px;*/
    border: 6px solid transparent;
  }
}

@media screen and (min-width: 1024px) {
  .service-img{
    max-width: 100%;
  }
  .service-section2 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding: 10px;
  }
}
