.footer {
  background-color: #c9c7c7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-logo {
  max-width: 110px;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
}
.footer-text{
  font-weight: 500;
  letter-spacing: 1px;
  color: white;
  font-size: 11px;
  text-transform: uppercase;
}
.footer-socials {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.footer-app {
  
  color: white;
  margin: 0 10px;
}
