.hero {
    position: relative;
    user-select: none;

}

.hero::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: black;
    opacity: 0.65;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
    background: grey;
    width: 40px;
    padding-left: 10px;

    height: 3px;
    margin-bottom: 20px;
    border-radius: 0;
}

.swiper-pagination-clickable .swiper-pagination-bullet-active {
    background: white;
    width: 40px;
    padding-left: 10px;

    height: 3px;
    margin-bottom: 20px;

}

/* target the active bullet */
/* span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: blue;
    opacity: 1;
  }
  
  /* target all bullets */
/* .swiper-pagination-bullet {
    background-color: red;
    opacity: 1;
  } */

.hero-swiper {
    width: 100vw;
    height: 100%;
    display: flex;
    /* flex-direction: row;
    justify-content: start; */

}

.hero img {
    width: 100vw;
    height: 100vh;
    background-size: cover;
    display: flex;
    background-position: center;
}

.hero-text {
    color: #fff;
    position: absolute;
    top: 20%;
    z-index: 1;
    padding-left: 80px;
    width: 100%;
}

.hero-text h1 {
    font-weight: 700;
    font-size: 100px;
    line-height: 100px;
    text-transform: uppercase;
    letter-spacing: 3px;
    width: 80%;

}

.separator {
    width: 50%;
    margin-top: 5%;
    background-color: #FDD947;
    height: 8px;
}

.hero-text p {
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-top: 5%;
    width: 80%;
}

.hero-btn {
    position: absolute;
    bottom: 5%;
    left: 45%;
    z-index: 100;
    color: #fff;
}



@media screen and (max-width: 480px) {

    .hero-text {
        top: 40%;
        padding-left: 30px;

    }

    .hero-text h1 {
        font-size: 34px;
        line-height: 38px;
        width: 80%;
        letter-spacing: 0px;
    }

    .separator {
        width: 60%;
    }

    .hero-text p {
        width: 80%;
    }

    .hero-btn {
        left: 40%;
        bottom: 10%;
    }
}


@media screen and (min-width : 500px) {
    .hero-text h1 {
        font-size: 56px;
        line-height: 60px;
    }

}


@media screen and (min-width : 900px) {

    .hero-text h1 {
        font-size: 100px;
        line-height: 100px;
    }


}