.header {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 45px;
    border-bottom: 1px solid rgb(233, 224, 224);
    position: fixed;
    z-index: 10;
    cursor: pointer;
}

/* .logo-menu {
    display: flex;
    align-items: left;   
} */


#lgmen {
    align-items: center;
    display: flex;
}

.header-bg {
    background: rgba(0, 0, 0, 0.9);
}

.header img {
    width: 160px;
    height: 65px;
    margin-top: 15px;
    margin-bottom: 10px;
    object-fit: fill;
    /* border-right: 2px solid rgb(233, 224, 224); */
    padding-right: 5%;
    
}

/* .logo{
    height: 100px;
    width: 120px;
} */

.logo:after {
    content: '';
    position: absolute;
    width: 196px;
    /* padding-top:30px ; */
    margin-top: 25px;
    margin-bottom: 20px;
    height: 30px;
    padding-left: 10px;
    padding-right: 0px;
    margin-right: 0;
    border-right: 2px solid rgb(233, 224, 224);
    left: 1px;
    top: 1px;
}



.header-menu {
    color: #fff;
    display: flex;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 600;
    font-size: 12px;
    /* padding: 0px; */
    margin-left: 5px;
    top: 50%;
}

/* .header ul{
    border-left: 1px solid rgb(233, 224, 224);
    padding: 0 40px;
} */
.header-item {
    padding: 20px;

}
.header-item .active{
    border-bottom: 1px solid white;
}

.header-item:hover {
    color: rgb(214, 210, 210);
    transition: all 0.4s ease;
    
}

.header-menu p {
    display: none;
    cursor: default;
}



.header-menu button {
    border: 2px solid #fff;
    padding: 4px 12px 3px;
    letter-spacing: 1px;
    font-size: 9px;
    color: #fff;
    background: transparent;
    font-weight: 600;
    transition: ease-out 0.3s;
    margin-left: 10px;
    cursor: pointer;

}

.header-menu button::before {
    flex-grow: 1;
}

.header-menu p {
    display: none;
    /* font-weight: 600; */
}

/* .header-menu button {
    border: 2px solid black;
    padding: 3px 9px 3px 9px;
    font-size: xx-small;
    margin-left: 5px;
    background-color: transparent;
    box-shadow: 0 0 0 0 white;
    transition: ease-out 0.3s;

} */

.header-menu button:hover {
    box-shadow: inset 300px 0 0 0 white;
    color: black;
}

.hamburger {
    color: black;
    display: none;

}

/* MEDIA QUERIES (SMALL AND MEDIUM DEVICES e.g Phones, Tablets) */

@media screen and (max-width: 992px) {
    .header {
        padding: 0 10px;
    }

    .logo:after {
        border: none;
    }

    .header-menu {
        position: fixed;
        top: 80px;
        left: -100%;
        flex-direction: column;
        background-color: black;
        color: #fff;
        width: 100%;
        height: 45%;
        text-align: left;
        transition: 0.4s;
    }


    .header ul {
        padding: 0px;
    }

    .header img {
        border-right: none;
        width: 150px;
    }

    .header-menu.active {
        left: 0;

    }

    .header-item {
        justify-content: center;
        z-index: 10000;
    }

    .menu {
        display: none;
    }

    .hamburger {
        display: flex;
        color: #fff;
        z-index: 10000;
    }
}


/* MEDIA QUERIES (LARGE DEVICES e.g PCs) */
@media screen and (min-width : 1200px) {
    .header-menu p {
        display: flex;
        padding: 0 4px;
    }
}